import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
// import * as firebase from 'firebase';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PushNotificationService {

  // messagingFirebase : firebase.messaging.Messaging;
  // db = firebase.firestore();

  constructor(    private adb: AngularFirestore,private messagingFirebase : AngularFireMessaging
    ) { 

      // this.messagingFirebase = firebase.messaging();


    }

    /* 
    Este método solicita permiso al usuario para recibir notificaciones push. 
    Si el usuario concede el permiso, intenta obtener un token de FCM y 
    lo resuelve en la promesa. Si el usuario no concede permiso, 
    rechaza la promesa.
    */
  requestPermission = () => {
    return new Promise(async (resolve, reject) => {
      const permsis = await Notification.requestPermission();
      if (permsis === "granted") {
        const tokenFirebase = await this.messagingFirebase.getToken.subscribe(c=>{
          resolve(c);
        });
      } else {
        reject(new Error("No se otorgaron los permisos"))
      }
    })
  }

  /* 
  Este observable se utiliza para recibir mensajes cuando la aplicación está en primer plano. 
  */
  private messaginObservable = new Observable<any>(observe => {
    this.messagingFirebase.onMessage(payload => {
      observe.next(payload)
    })
    
  })

/* 
  Este método retorna el observable messaginObservable, 
  que permite a otros componentes suscribirse a él
   para recibir notificaciones entrantes en tiempo real.
  */

  receiveMessage() {
    return this.messaginObservable;
  }

 /* 
  Este método actualiza el token FCM del usuario
  en Firestore, lo cual es necesario si quieres
   poder enviar notificaciones específicas a 
   un usuario determinado. 
 */ 

  updatefcmToken(token) {
    let uid = localStorage.getItem("uid") || null;
    if (uid) {
      this.adb.collection("users").doc(uid).update({ fcm_token: token }).then(c=> {
        //console.log(Update FCM Token'),
      });
    }
  }

}
